import React from "react";
import Store from "./store/Store";
import LoginForm from "./components/LoginForm";
import Header from "./components/Header";
import {Route, Routes} from "react-router-dom";
import TaskPage from "./pages/TaskPage";
import TablePage from "./pages/TablePage";
import Api from "./api/Api";
import './css/App.css';
import EditTask from "./pages/EditTask";
import ForgottenPassword from "./components/ForgottenPassword";
import MyTaskPage from "./pages/MyTaskPage";
import NewTask from "./pages/NewTask";

class App extends React.Component {
    componentDidMount () {

            if (window.swUpdateReady) {
                window.swUpdateReady = false;
                window.stop();
                window.location.reload();
            }

    }
    async componentWillMount() {
        await Api.initUser();
    }

    render() {
        var store = Store.getState();
        if (store.loading) {
            return (
                <>
                    <Header/>
                    <div className="App">
                        <div className={"container"}>
                            Loading, please wait.
                        </div>
                    </div>
                </>
            );
        } else {
            if (store.isLoggedIn) {
                return (
                    <>
                        <Header/>
                        <div className={"container"}>
                            <Routes>
                                <Route path='/' element={<TablePage/>}/>
                                <Route path='/task' element={<TaskPage/>}>
                                    <Route path=':tableId' element={<TaskPage/>}/>
                                </Route>
                                <Route path='/my-task' element={<MyTaskPage/>}>
                                    <Route path=':tableId' element={<MyTaskPage/>}/>
                                </Route>
                                <Route path='/edit-task' element={<EditTask/>}>
                                    <Route path=':taskGuid' element={<EditTask/>}/>
                                </Route>
                                <Route path='new-task' element={<NewTask/>}>
                                    <Route path=':table_id' element={<NewTask/>}/>
                                </Route>
                            </Routes>
                        </div>
                    </>
                );
            } else {
                return (<>
                        <Header/>
                        <div className={"container"}>
                            <Routes>
                                <Route path='/*' element={<LoginForm />}></Route>
                                <Route path='/forgotten-password' element={<ForgottenPassword/>}></Route>
                            </Routes>
                        </div>
                    </>


                )
            }
        }
    }
}

export default App;
