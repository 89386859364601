import React from 'react';

class InputField extends React.Component{
    render (){
        if(this.props.type ==='dropdown'){
            return (
                <div className={"input-field"}>
                    <select
                        className={"input"}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                    >
                        {this.props.options && this.props.options.map((item) =>{
                            return (<option key={item.id} value={item.id}>{item.name}</option>)
                        } )}
                    </select>
                </div>
            )
        }else {
            return (
                <div className={"input-field"}>
                    <input
                        className={"input"}
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                    />
                </div>
            )
        }
    }
}
export default InputField;