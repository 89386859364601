import React, { useState} from 'react';
import { useNavigate, useParams,} from "react-router-dom";
import Api from "../api/Api";
import {Col, Row, Card} from "react-bootstrap";
import Store from "../store/Store";


export default () => {
    let params = useParams();
    const navigate = useNavigate();

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10) {dd='0'+dd;}
    if(mm<10) {mm='0'+mm;}


    let {tasks, user} = Store.getState();
    let task = tasks.find(element => element.guid === params.taskGuid);
    if(!task){
        return (<p>Az oldal nem található</p>);
    }

    const [modifiedTask,setModifiedTask] = useState(task);

    const setValue = (field, value)=>{
        setModifiedTask({...modifiedTask, [field]:value, dirty:1});
    }

    const saveChanges = ()=>{
        if(window.confirm('Biztosan be akarja fejezni a feladatot?')) {
            let param = {
                id: modifiedTask.guid,
                task:modifiedTask
            }
            Api.updateTask(param);
        }

    }
    const isOwn = (task)=>{
        let subtask = task.sub_tasks.find(subtask=>subtask.user_id && subtask.user_id === user.id);
        if(subtask){
            return true;
        }else{
            return false;
        }
    }
    const goBack = ()=> {
        navigate(-1);
    }


    const renderSubTask = (item) => {
        return (
            <Card border="light"  key={'subtask_'+item.id}>
                
                <Card.Header><strong>{item.task_name ==='Munkaerő'?item.task_name+" - "+item.item_name:item.task_name}</strong></Card.Header>
                <Card.Body>
                    <Card.Text>
                        {item.task_name !=='Munkaerő' &&
                        <Row>
                            <Col><span><strong>Megnevezés</strong></span></Col>
                            <Col><span>{item.item_name}</span></Col>
                        </Row>
                        }
                        <Row>
                            <Col><span><strong>Költség/ha</strong></span></Col>
                            <Col><span>{item.cost}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Mennyiség</strong></span></Col>
                            <Col><span>{item.amount}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Teljes költség</strong></span></Col>
                            <Col><span>{item.total_cost}</span></Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }
    return (
        <div>
            <div className={"page-head my-2 d-flex"}>
                <h3 className="title"><strong>{task.name}</strong></h3>
                <div>
                    <button onClick={()=>{goBack()}} className={"btn button"}>{"< Vissza"} </button>
                </div>
            </div>
            <hr/>
            <Card border="light">
                <Card.Header><strong>{task.name}</strong></Card.Header>
                <Card.Body>
                    <Card.Text>
                        <Row>
                            <Col><span><strong>Terület</strong></span></Col>
                            <Col><span>{task.task_area}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Költség/ha</strong></span></Col>
                            <Col><span>{task.task_cost}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Kezdés</strong></span></Col>
                            <Col><span>{task.started_at}</span></Col>
                        </Row>
                        {task.completed_at && <Row>
                            <Col><span><strong>Befejezés</strong></span></Col>
                            <Col><span>{task.completed_at}</span></Col>
                        </Row>}
                        {!task.completed_at && isOwn(task) && <><Row>
                            <Col><span><strong>Befejezés</strong></span></Col>
                            <Col>
                                <input type="date" onChange={(e) => {
                                    setValue('completed_at', e.target.value)
                                }}/>
                            </Col>
                        </Row>
                        <button className={"btn button mx-1"} onClick={()=>{saveChanges()}}>Mentés</button>
                        </>}
                        <div>
                            {task.sub_tasks && task.sub_tasks.map(item => renderSubTask(item))}
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

        </div>
    )
}