import constants from "../constants";
import Store from "../store/Store";

const axios = require('axios');

class Api {
    static async IndexedDb() {
        return await new Promise(async function (resolve) {
            var request = await indexedDB.open(constants.IDB_NAME, constants.IDB_VERSION);
            var db;
            request.onsuccess = async function (event) {
                db = event.target.result;
                return resolve(db);
            };
            request.onupgradeneeded = async function (event) {
                db = event.target.result;
                await db.createObjectStore("taskLastSync", {keyPath: "id"});
                await db.createObjectStore("tables", {keyPath: "table_id"});
                await db.createObjectStore("harvestYears", {keyPath: "id"});
                await db.createObjectStore("user", {autoIncrement: true});
                await db.createObjectStore("actualHarvestYear", {keyPath: "id"});
                await db.createObjectStore("tasks", {keyPath: "guid"});
                await db.createObjectStore("tableLastSync", {autoIncrement: true});
                await db.createObjectStore("farms", {keyPath: "id"});
                await db.createObjectStore("operations", {keyPath: "id"});
                let transaction = event.target.transaction;
                transaction.oncomplete = function (event) {
                    return resolve(db);
                };
            };
        });
    }

    static async getFromIDB(store) {
        return await Api.IndexedDb().then((db) => {
            return new Promise(async function (resolve) {
                var transaction = db.transaction([store]);
                var objectStore = transaction.objectStore(store);
                var request = objectStore.getAll();
                request.onsuccess = function (event) {
                    return resolve(event.target.result);
                };
            });
        })
    }

    static async sendOfflineData() {
        let tasks = await Api.getFromIDB('tasks');
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.dirty) {
                let param = {
                    id: task.guid,
                    task: task
                };
                await Api.updateTask(param);
            }
        }
    }

    static async getHarvestYear() {
        const {user} = Store.getState();
        const url = constants.API_URL + 'common/preselect';
        const data = {
            user_id: user.id,
            token: user.token
        };
        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then(async (response) => {
                let result = response.data;
                if (result.success) {
                    // console.log(result.harvest_years);

                    Api.IndexedDb().then((db) => {
                        var harvestYearsStore = db.transaction("harvestYears", "readwrite").objectStore("harvestYears");
                        harvestYearsStore.clear();
                        result.harvest_years.forEach((item) => {
                            harvestYearsStore.add(item);
                        });
                    });

                    Api.IndexedDb().then((db) => {
                        var actualHarvestYearStore = db.transaction("actualHarvestYear", "readwrite").objectStore("actualHarvestYear");
                        actualHarvestYearStore.clear();
                        actualHarvestYearStore.add({id: result.actual, value: result.actual});
                    });
                    Store.dispatch({type: 'actualHarvestYear', value: result.actual});
                    Store.dispatch({type: 'harvestYears', value: result.harvest_years});
                } else {
                    let actualHarvestYear = await Api.getFromIDB('actualHarvestYear')
                    let harvestYears = await Api.getFromIDB('harvestYears')
                    Store.dispatch({
                        type: 'actualHarvestYear',
                        value: actualHarvestYear && actualHarvestYear[0] ? actualHarvestYear[0].value : actualHarvestYear.value
                    });
                    Store.dispatch({type: 'harvestYears', value: harvestYears});
                }
                return true;
            }).catch(async (e) => {
                let actualHarvestYear = await Api.getFromIDB('actualHarvestYear');
                let harvestYears = await Api.getFromIDB('harvestYears');
                Store.dispatch({
                    type: 'actualHarvestYear',
                    value: actualHarvestYear && actualHarvestYear[0] ? actualHarvestYear[0].value : actualHarvestYear.value
                });
                Store.dispatch({type: 'harvestYears', value: harvestYears});
                return false;
            });

    }

    static async getTables() {
        const {user, actualHarvestYear} = Store.getState();
        const url = constants.API_URL + 'table/index';
        //let last_sync = localStorage.getItem('tableLastSync')
        const data = {
            user_id: user.id,
            token: user.token,
            harvest_year_id: actualHarvestYear,
            //last_sync:last_sync
        };
        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then(async (response) => {
                let result = response.data;
                if (result.success) {
                    Api.IndexedDb().then((db) => {
                        let tablesStore = db.transaction("tables", "readwrite").objectStore("tables");
                        tablesStore.clear();
                        result.tables.forEach((item) => {
                            tablesStore.add(item);
                        });
                    });

                    Store.dispatch({type: 'tables', value: result.tables});
                } else {
                    let tables = await Api.getFromIDB('tables')
                    Store.dispatch({type: 'tables', value: tables});
                }
                return true;
            }).catch(async (e) => {
                let tables = await Api.getFromIDB('tables')
                Store.dispatch({type: 'tables', value: tables});
                return false;
            });
    }

    static async getTasks() {
        const {user, actualHarvestYear} = Store.getState();
        const url = constants.API_URL + 'task/all-task';
        const data = {
            user_id: user.id,
            token: user.token,
            harvest_year_id: actualHarvestYear,
        };
        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then(async (response) => {
                let result = response.data;
                if (result.success) {
                    Api.IndexedDb().then((db) => {
                        let tasksStore = db.transaction("tasks", "readwrite").objectStore("tasks");
                        tasksStore.clear();
                        result.tasks.forEach((item) => {
                            item.dirty = 0;
                            tasksStore.add(item);
                        });
                    });
                    localStorage.setItem('taskLastSync', Math.round(+new Date() / 1000) + (60 * 60));
                    Store.dispatch({type: 'tasks', value: result.tasks});
                } else {
                    let tasks = await Api.getFromIDB('tasks')
                    Store.dispatch({type: 'tasks', value: tasks});
                }
                return true;
            }).catch(async (e) => {
                let tasks = await Api.getFromIDB('tasks')
                Store.dispatch({type: 'tasks', value: tasks});
                return false;
            });
    }

    static async setSubscribeToken() {
        const {user, firebase_subscribe_token} = Store.getState();
        let data = {
            user_id: user.id,
            token: user.token,
            firebase_token: firebase_subscribe_token
        };
        await axios.post(constants.API_URL + 'user/set-firebase-token', JSON.stringify(data), constants.POST_HEADER)
            .catch(async (e) => {
                return false;
            });
    }

    static async updateTask(param) {
        const {user} = Store.getState();
        const url = constants.API_URL + 'task/update';

        const data = {
            user_id: user.id,
            token: user.token,
            ...param
        };
        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then((response) => {
                let result = response.data;
                if (!result.success) {
                    alert(result.error);
                } else {
                    Api.getTasks();
                    alert('Sikeres mentés');
                }
                return true;
            }).catch(async (e) => {
                alert('Sajnos nem tudtuk elmenteni a változásokat mert nincs internetkapcsolat! Amint lesz internet kapcsolata, az adatokat automatikusan feldolgozzuk.');
                await Api.IndexedDb().then((db) => {
                    return new Promise(async function (resolve) {
                        var transaction = db.transaction(['tasks'], 'readwrite');
                        var objectStore = transaction.objectStore('tasks');
                        var request = objectStore.put(param.task);
                        request.onsuccess = function (event) {
                            // let data = event.target.result;
                            // console.log(data);
                            // console.log(param.task);
                            return resolve(true);
                        };
                    });
                });
                return true;

            });
    }

    static forgotPassword(email) {
        let url = constants.API_URL + "user/forgot-password"
        let data = {
            username: email
        }
        axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then((response) => {
                let result = response.data;
                if (result.success) {
                    alert('Az új jelszó beállításához szükséges email-t elküldtük!');
                } else {
                    alert(result.error);

                }
                return true;
            }).catch((e) => {
            alert('Sajnos hiba történt, kérjük próbálja meg később!');
            return false;
        });
    }

    static async login(username, password) {
        let url = constants.API_URL + 'user/login';
        let data = {
            username: username,
            password: password
        };

        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then(async (response) => {
                let result = response.data;
                if (result.success) {
                    let user = result.user;
                    user.token = result.token;
                    Api.IndexedDb().then((db) => {
                        let userStore = db.transaction("user", "readwrite").objectStore("user");
                        userStore.clear();
                        userStore.add(user);
                    });
                    Store.dispatch({type: 'user', value: result.user});
                    Store.dispatch({type: 'loading', value: true});
                    Store.dispatch({type: 'isLoggedIn', value: true});
                    if (user && user.username) {
                        await Api.sendOfflineData()
                        await Api.getHarvestYear();
                        await Api.getTables();
                        await Api.setSubscribeToken()
                        await Api.getTasks();
                        await Api.getFarms();
                        await Api.getOperations();
                        Store.dispatch({type: 'loading', value: false});
                    }
                } else {
                    alert(result.error);
                }
                return true;
            }).catch(async (e) => {
                return false;
            });
    }

    static async logout() {
        await Api.sendOfflineData();
        //megnézzük hogy van-e offline létrehozott/módosított adat

        let hasOfflineData = false;
        let tasks = await Api.getFromIDB('tasks');
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.dirty) {
                hasOfflineData = true;
            }
        }
        if(!hasOfflineData || (hasOfflineData && window.confirm('Kilépéskor az offline tárolt adatokat töröljük. Biztosan ki szeretne lépni? During logoff, data stored offline will be deleted. Do you want to continue?'))){
            const {user} = Store.getState();
            const url = constants.API_URL + 'user/logout';
            const data = {
                user_id: user.id,
                token: user.token,
            };
            indexedDB.deleteDatabase(constants.IDB_NAME);

            Store.dispatch({type: 'loading', value: false});
            Store.dispatch({type: 'isLoggedIn', value: false});
            Store.dispatch({type: 'user', value: {}});
            return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
                .then(async (response) => {
                    let result = response.data;
                    if (!result.success) {
                        alert(result.error);
                    }
                    return true;
                }).catch(async (e) => {
                    return false;
                });

        }

    }

    static async initUser() {
        let user = await Api.getFromIDB('user');

        if (user.length === 0) {
            Store.dispatch({type: 'user', value: {}});
            Store.dispatch({type: 'isLoggedIn', value: false});
            Store.dispatch({type: 'loading', value: false});
            return false;
        }
        if (user && user[0]) {
            user = user[0];
        }
        const url = constants.API_URL + 'user/validate-user';
        const data = {
            user_id: user.id,
            token: user.token,
        };
        axios.post(url, data, constants.POST_HEADER).then(async (response) => {
            if (response.data.success) {
                if (user && user.username) {
                    Store.dispatch({type: 'user', value: user});
                    Store.dispatch({type: 'isLoggedIn', value: true});
                } else {
                    indexedDB.deleteDatabase(constants.IDB_NAME);
                    Store.dispatch({type: 'user', value: {}});
                    Store.dispatch({type: 'isLoggedIn', value: false});
                    Store.dispatch({type: 'loading', value: false});
                }
                //Aratási évek betöltése
                if (user && user.username) {
                    await Api.sendOfflineData()
                    await Api.getHarvestYear();
                    await Api.getTables();
                    await Api.setSubscribeToken()
                    await Api.getTasks();
                    await Api.getFarms();
                    await Api.getOperations();
                    Store.dispatch({type: 'loading', value: false});
                }
            } else {
                indexedDB.deleteDatabase(constants.IDB_NAME);
                Store.dispatch({type: 'loading', value: false});
                Store.dispatch({type: 'isLoggedIn', value: false});
                Store.dispatch({type: 'user', value: {}});
                alert(response.data.error);
            }
        }).catch(async (e) => {
            if (user && user.username) {
                Store.dispatch({type: 'user', value: user});
                Store.dispatch({type: 'isLoggedIn', value: true});
            } else {
                Store.dispatch({type: 'user', value: {}});
                Store.dispatch({type: 'isLoggedIn', value: false});
                Store.dispatch({type: 'loading', value: false});
            }
            //Aratási évek betöltése
            if (user && user.username) {
                await Api.sendOfflineData()
                await Api.getHarvestYear();
                await Api.getTables();
                await Api.setSubscribeToken()
                await Api.getTasks();
                await Api.getFarms();
                await Api.getOperations();
                Store.dispatch({type: 'loading', value: false});
            }
        });

    }

    static async getFarms() {
        const {user, actualHarvestYear} = Store.getState();
        const url = constants.API_URL + 'common/get-farms';
        const data = {
            user_id: user.id,
            token: user.token,
            harvest_year_id: actualHarvestYear,
        };
        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then(async (response) => {
                let result = response.data;
                if (result.success) {
                    Api.IndexedDb().then((db) => {
                        let farmsStore = db.transaction("farms", "readwrite").objectStore("farms");
                        farmsStore.clear();
                        result.farms.forEach((item) => {
                            farmsStore.add(item);
                        });
                    });
                    Store.dispatch({type: 'farms', value: result.farms});
                } else {
                    let farms = await Api.getFromIDB('farms');
                    Store.dispatch({type: 'farms', value: farms});
                }
            }).catch(async (e) => {
                let farms = await Api.getFromIDB('farms')
                Store.dispatch({type: 'farms', value: farms});
                return false;
            });

    }

    static async getOperations() {
        const {user, actualHarvestYear} = Store.getState();
        const url = constants.API_URL + 'task/get-operations';
        const data = {
            user_id: user.id,
            token: user.token,
            harvest_year_id: actualHarvestYear,
        };
        return await axios.post(url, JSON.stringify(data), constants.POST_HEADER)
            .then(async (response) => {
                let result = response.data;
                if (result.success) {
                    Api.IndexedDb().then((db) => {
                        let operationStore = db.transaction("operations", "readwrite").objectStore("operations");
                        operationStore.clear();
                        result.operations.forEach((item) => {
                            operationStore.add(item);
                        });
                    });
                    Store.dispatch({type: 'operations', value: result.operations});
                } else {
                    let operations = await Api.getFromIDB('operations');
                    Store.dispatch({type: 'operations', value: operations});
                }
            }).catch(async (e) => {
                let operations = await Api.getFromIDB('operations')
                Store.dispatch({type: 'operations', value: operations});
                return false;
            });

    }
}

export default Api