import React from "react";
import Store from "../store/Store";
import {CloseButton, Container, Nav, Navbar} from "react-bootstrap";
import {Offcanvas} from "react-bootstrap"
import {Link} from "react-router-dom";
import Api from "../api/Api";
import constants from "../constants";

import '../css/header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actualHarvestYear: null,
            harvestYears: [],
            expanded:false,
        }
    }

    async doLogout() {
        await Api.logout();
        this.setState({expanded:false});
    }

    setHarvestYear = async (id) => {/*TODO IDB mentés*/
        Api.IndexedDb().then((db) => {
            let actualHarvestYearStore = db.transaction("actualHarvestYear", "readwrite").objectStore("actualHarvestYear");
            actualHarvestYearStore.clear();
            actualHarvestYearStore.add({id: id, value: id});
        });
        Store.dispatch({type: 'actualHarvestYear', value: id});
        Store.dispatch({type: 'loading', value: true});
        await Api.getTables();
        await Api.getTasks();
        Store.dispatch({type: 'loading', value: false});
    }

    render() {
        const {isLoggedIn,harvestYears,actualHarvestYear} = Store.getState();
        if (isLoggedIn) {
            return (
                <Navbar expand={false} collapseOnSelect={true}>
                    <Container fluid>
                        <Link to="/" className={'navbar-brand'}><img className={"navbar-img"} src="/img/icon.png" alt=""/></Link><h3>Agrekord</h3>
                        <Navbar.Toggle  onClick={() => this.setState({expanded:true})} aria-controls="offcanvasNavbar"/>
                        <Navbar.Offcanvas show={this.state.expanded}
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="end"
                        >
                            <Offcanvas.Header>
                                <Offcanvas.Title id="offcanvasNavbarLabel">
                                    <select style={{width: '200px'}} onChange={(e) => this.setHarvestYear(e.target.value)} value={actualHarvestYear}>
                                        {harvestYears && harvestYears.length && harvestYears.map((item) => {
                                            if (item.id === actualHarvestYear) {
                                                return (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                )
                                            } else {
                                                return (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                )
                                            }
                                        })}
                                    </select>
                                    <CloseButton onClick={() => this.setState({expanded:false})}></CloseButton>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="d-flex flex-column">
                                <Nav className="justify-content-start flex-grow-1 pe-3" >
                                    <Link onClick={() => this.setState({expanded:false})} className={"nav-link"} to="/">Főoldal</Link>
                                    <Link onClick={() => this.setState({expanded:false})} className={"nav-link"} to="/my-task">Feladatok</Link>
                                    <Link onClick={() => this.setState({expanded:false})} className={"nav-link"} to="/new-task">Új feladat</Link>
                                    <hr/>
                                    <a href='#' className={"nav-link"} onClick={() => this.doLogout()}>Kijelentkezés</a>

                                </Nav>
                                <div className="align-self-end"><p>{constants.APP_VERSION}</p></div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            );
        } else {
            return (
                <div className={"navbar"}>
                    <img className={"navbar-img"} style={{margin: '5px 10px'}} src="/img/icon.png" alt=""/>
                    <h3>Agrekord</h3>
                    <span style={{width: '70px'}}></span>
                </div>
            );
        }

    }
}

export default Header;