import {createStore} from "redux";


const initialState = {
    loading: true,
    isLoggedIn: 0,
    username: '',
    firebase_subscribe_token: '',
    user: {},
    harvestYears: [],
    actualHarvestYear: null,
    tables: [],
    tasks: [],
    selectedTable: null,
    selectedTask: null,
    farms: null,
    operations: null,
}

/*
function allStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while ( i-- ) {
        values.push( localStorage.getItem(keys[i]) );
    }

    return values;
}
*/
function storeFunction(state = initialState, data) {
    if (typeof state === 'undefined') {
        return 'Error: state is not defined';
    }
    switch (data.type) {
        case 'loading':
            return Object.assign({}, state, {
                loading: data.value
            });
        case 'isLoggedIn':
            return Object.assign({}, state, {
                isLoggedIn: data.value
            });
        case 'username':
            return Object.assign({}, state, {
                username: data.value
            });
        case 'user':
            return Object.assign({}, state, {
                user: data.value
            });
        case 'firebase_subscribe_token':
            return Object.assign({}, state, {
                firebase_subscribe_token: data.value
            });
        case 'harvestYears':
            return Object.assign({}, state, {
                harvestYears: data.value
            });
        case 'actualHarvestYear':
            return Object.assign({}, state, {
                actualHarvestYear: data.value
            });
        case 'tables':
            return Object.assign({}, state, {
                tables: data.value
            });
        case 'tasks':
            return Object.assign({}, state, {
                tasks: data.value
            });
        case 'selectedTable':
            return Object.assign({}, state, {
                selectedTable: data.value
            });
        case 'selectedTask':
            return Object.assign({}, state, {
                selectedTask: data.value
            });
        case 'farms':
            return Object.assign({}, state, {
                farms: data.value
            });
        case 'operations':
            return Object.assign({}, state, {
                operations: data.value
            });
        default:
            return state
    }
}

const Store = createStore(storeFunction);
export default Store;