import React, { useState} from 'react';
import Store from "../store/Store";
import {Link, useParams} from "react-router-dom";
import {Card, Col, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'

export default (props) => {
    let params = useParams();
    let tasksByTable = false;
    let table = false;
    let {tasks, tables, user} = Store.getState();
    const [isDoneFilter,setIsDoneFilter] = useState(false);
    const [order,setOrder] = useState(0);

    let filteredTasks = [];


    for (let i = 0; i < tasks.length; i++) {
        let task = tasks[i];
        if(task.sub_tasks.find(subtask=>subtask.user_id && subtask.user_id === user.id)) {
            filteredTasks = [...filteredTasks,task];
        }
    }
    tasks = filteredTasks;




    const navigate = useNavigate();

    const [searchKey,setSearchKey] = useState('');
    if (params.tableId) {
        table = tables.find(element => element.table_id === params.tableId);
        tasksByTable = tasks.filter(element => element.table_id === params.tableId);
    }
    const tableName = (id)=>{
        let table = tables.find((table)=>{return Number(table.table_id) === Number(id)});
        return table?table.name:'';
    }
    const redirectToTable = (e,url)=>{
        e.preventDefault();
        e.stopPropagation();
        navigate(url);
    }

    const renderTask = (task) => {
        return (
            <Col key={'task_'+task.id} xs={12} md={6} lg={4}>
                <Card border="light" className="mb-2" onClick={(e)=>{redirectToTable(e,"/edit-task/"+task.guid)}}>
                    <Card.Header><strong><FontAwesomeIcon className="icon" icon={'seedling'} /> {task.name}</strong>{task.completed_at ? <FontAwesomeIcon className="icon" style={{float:'right'}} icon={faCircleCheck} />:<></>}</Card.Header>
                    <Card.Body>
                            <Row>
                                <Col><span><strong>Tábla</strong></span></Col>
                                <Col><span><button className={"btn-link"} onClick={(e)=>{redirectToTable(e,"/task/"+task.table_id)}}>{tableName(task.table_id)}</button></span></Col>
                            </Row>
                            <Row>
                                <Col><span><strong>Aratási év</strong></span></Col>
                                <Col><span>{task.harvest_year_name}</span></Col>
                            </Row>
                            <Row>
                                <Col><span><strong>Terület</strong></span></Col>
                                <Col><span>{task.task_area}</span></Col>
                            </Row>
                            <Row>
                                <Col><span><strong>Költség/ha</strong></span></Col>
                                <Col><span>{task.task_cost}</span></Col>
                            </Row>
                            <Row>
                                <Col><span><strong>Kezdés</strong></span></Col>
                                <Col><span>{task.started_at}</span></Col>
                            </Row>
                            {task.completed_at && <Row>
                                <Col><span><strong>Befejezés</strong></span></Col>
                                <Col><span>{task.completed_at}</span></Col>
                            </Row>}
                    </Card.Body>
                </Card>
            </Col>
        )
    }
    const renderHead = ()=>{
        return (<div className={"page-head my-2 d-flex"}>
            <strong>{table.name}</strong>
            <div>
                <Link to={"/"} className={"btn button"}>{"< Vissza"} </Link>
            </div>
        </div>);
    }


    const onIsDone = (tasks)=>{
        if(isDoneFilter==true) {
            return tasks.filter(task => {return !!task.completed_at});
        }else{
            return tasks;
        }
    }
    const onOrder = (tasks)=>{
        let sorted = null;
        if(order == 1) {
            sorted = tasks.sort((a, b) => (a.started_at > b.started_at) ? 1 : ((b.started_at > a.started_at) ? -1 : 0));
        }else{
            sorted= tasks.sort((a, b) => (b.started_at > a.started_at) ? 1 : ((a.started_at > b.started_at) ? -1 : 0));
        }
        return sorted;
    }

    if (table) {
        if (tasksByTable && tasksByTable.length) {
            return (
                <div>
                    {renderHead()}
                    <Row>
                        {tasksByTable && tasksByTable.length && tasksByTable.map((task) => {
                            return renderTask(task)
                        })}
                    </Row>
                </div>
            )
        } else {
            return (
                <div>
                    {renderHead()}
                    Jelenleg nincs faladat ehez a táblához
                </div>
            )
        }
    }else{
        let filteredTasks = tasks.filter(task => task.name.includes(searchKey));
        filteredTasks = onIsDone(filteredTasks);
        filteredTasks = onOrder(filteredTasks);
        return (
            <>
                <input className={'search-input input'} onChange={(e) => {
                    setSearchKey( e.target.value);
                }} type="text" placeholder={'Keresés...'}/>
                <div className="filter">
                    <Row>
                        <DropdownButton className="col-7" variant="success" title="Szűrés">
                            <Dropdown.Item onClick={()=>{setOrder(1)}} className={order==1 &&'selected'}>Kezdés időpontja szerint növekvő</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{setOrder(0)}} className={order==0 &&'selected'}>Kezdés időpontja szerint csökkenő</Dropdown.Item>
                        </DropdownButton>
                    <Form.Check
                        className="col-5"
                        type="checkbox"
                        label='Elvégzett feladatok'
                        value={isDoneFilter}
                        onChange={()=>{setIsDoneFilter(isDoneFilter?false:true)}}
                    />
                    </Row>
                </div>
                <Row>
                    {filteredTasks  && filteredTasks.map((task) => {
                        return renderTask(task)
                    })}
                    {!filteredTasks.length && <strong>Nincs a keresési feltételeknek megfelelő feladat!</strong>

                    }
                </Row>
            </>
        )
    }

}