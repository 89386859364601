import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { getMessaging, getToken,onMessage  } from "firebase/messaging";
import {initializeApp} from 'firebase/app';


import Store from "./store/Store";
import Api from "./api/Api";
import { library } from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
library.add(fas);


const render = () => ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('root')
);
const refreshData = async ()=> {
    let user = null;

    user = await Api.IndexedDb().then((db) => {
        return new Promise(async function (resolve) {
            var transaction = db.transaction(["user"]);
            var objectStore = transaction.objectStore("user");
            var request = objectStore.getAll();
            request.onsuccess = function (event) {
                return resolve(event.target.result);
            };
        });
    })
    if (user && user[0]) {
        user = user[0];
    }
    if (user && user.username) {
        Store.dispatch({type: 'user', value: user});
        Store.dispatch({type: 'isLoggedIn', value: true});
    } else {
        Store.dispatch({type: 'user', value: {}});
        Store.dispatch({type: 'isLoggedIn', value: false});
        Store.dispatch({type: 'loading', value: false});
    }
    if (user && user.username) {
        await Api.getHarvestYear();
        await Api.getTables();
        await Api.setSubscribeToken()
        await Api.getTasks();

        Store.dispatch({type: 'loading', value: false});
    }
}
render()
Store.subscribe(render);
serviceWorker.register();

reportWebVitals();


/**
 * FIREBASE PUSH NOTIFICATION
 * */

const firebaseApp = initializeApp({
    apiKey: "AIzaSyBJoXbrKB-O-0UBQk-8YAO_FS0bDOA-dSw",
    authDomain: "agrekordmobile.firebaseapp.com",
    projectId: "agrekordmobile",
    storageBucket: "agrekordmobile.appspot.com",
    messagingSenderId: "844548925406",
    appId: "1:844548925406:web:4fd0de05f467b69db89f4f"
});


const messaging = getMessaging(firebaseApp);

getToken(messaging, { vapidKey: 'BCjnHtPyLmv7jdakjIzMbrpJp8pXPYY67ITWmINKAzRHIvyc4cpg5FZuMNvZWjwmwgWrVrUDiP8Nffg-qxd4Gbw' }).then((currentToken) => {
    if (currentToken) {
        console.log('current_token',currentToken);
        Store.dispatch({type:'firebase_subscribe_token',value:currentToken});
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
});
onMessage(messaging, (payload) => {
    refreshData();
});
window.addEventListener('online', async(e)=> { await Api.sendOfflineData(); });
