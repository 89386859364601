import {useState} from 'react';
import {Link} from "react-router-dom";
import InputField from "./_components/InputField";
import SubmitButton from "./_components/SubmitButton";
import React from "react";
import Api from "../api/Api";

export default () => {
    const [email, setEmail] = useState('');
    const newPassword = async () => {
        Api.forgotPassword(email);
    }
    return (
        <div className={"login-form"}>
            <InputField
                type="text"
                placeholder="Email"
                value={email ? email : ''}
                onChange={(val) => {setEmail(val)}}
            />
            <SubmitButton
                text="Új jelszó igénylése"
                onClick={() => {newPassword();}}
            />
            <br/>
            <br/>
            <Link to={'/'}>Belépés</Link>
        </div>
    )
}