import React from "react";
import Store from "../store/Store";
import {Col, Row,Card} from "react-bootstrap";
import '../css/table.css'
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default (props)=>{
    const [keyword, setKeyword] = useState('');
    const navigate = useNavigate();
    const redirect = (e,url)=>{
        e.preventDefault();
        e.stopPropagation();
        navigate(url);
    }
    let {tables} = Store.getState();


    const renderTable = (table) => {
        return (
            <Col key={'table_'+table.table_id} xs={12} md={6} lg={4}>
                    <Card border="light" className="mb-2" onClick={(e) => {
                        redirect(e, `/task/${table.table_id}`)
                    }}>
                        <Card.Header><FontAwesomeIcon className="icon" icon={'location-dot'} /> <strong>{table.name}</strong></Card.Header>
                        <Card.Body>
                                <Row>
                                    <Col><span><strong>Terület</strong></span></Col>
                                    <Col><span>{table.area}</span></Col>
                                </Row>
                                <Row>
                                    <Col><span><strong>Parcellák száma</strong></span></Col>
                                    <Col><span>{table.parcel_count}</span></Col>
                                </Row>
                        </Card.Body>
                    </Card>
            </Col>
        )
    }


    if(tables) {
        let filteredTables = tables.filter(table => table.name.includes(keyword));
        return (
            <div>
                <input className={'search-input input'} onChange={(e) => {
                    setKeyword(e.target.value)
                }} type="text" placeholder={'Keresés...'}/>
                <Row>
                    {filteredTables && filteredTables.map((table) => {
                        return renderTable(table)
                    })}
                </Row>
            </div>
        )
    }else{
        return (<div>Nincs megjeleníthető tábla!</div>);
    }

}