import React, { useEffect, useState } from 'react';
import Store from "../store/Store";
import Select from "react-select";
import Api from "../api/Api";
import { Link, useParams } from "react-router-dom";


export default (props) => {
    const {table_id} = useParams(); 
    let { tables, farms, operations } = Store.getState();
    let selectedTable = null; // ha kapunk url ben tábla id-t akkor beállítjuk azt
    let tableOptions = [];
    tables.forEach(item => {
        tableOptions.push({ value: item.table_id, label: item.name });
        if(item.table_id == table_id){
            selectedTable = [{ value: item.table_id, label: item.name }];
        }
    });
   
    let _guid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase());
    const { user } = Store.getState();
    const [myState, setMyState] = useState({
        guid: _guid,
        name: null,
        harvest_year_id: null,
        table_id: selectedTable,
        farm_id: null,
        operation_id: null,
        started_at: null,
        completed_at: null,
        dirty: 1,
        harvest_year_name: null,
        id: null,
        sub_tasks: [{ user_id: user.id }],
        task_area: '',
        task_cost: ''
    });
    const [changed,setChanged] = useState(0);
    let farmOptions = [];
    farms.forEach(item => {
        farmOptions.push({ value: item.id, label: item.name });
    });
    let operationOptions = [];
    operations.forEach(item => {
        if (myState.farm_id && myState.farm_id.value) {
            if (Number(item.farm_id) === Number(myState.farm_id.value)) {
                operationOptions.push({ value: item.id, label: item.name });
            }
        } else {
            operationOptions.push({ value: item.id, label: item.name });
        }
    });
    const setInputValue = (property, val) => {
        let _state = myState;
        _state[property] = val;
        setMyState(_state);
        setChanged(changed+1);
        console.log(myState);
    }

    const submitData = async () => {
        let task = myState;
        let { actualHarvestYear } = Store.getState();
        if (task.name && task.farm_id && task.table_id && task.operation_id) {
            task.farm_id = Number(task.farm_id.value);
            task.harvest_year_id = actualHarvestYear;
            task.operation_id = Number(task.operation_id.value);
            let tableIds = [];

            task.table_id.forEach(item => {
                tableIds.push(item.value);
            });
            task.table_id = tableIds;
            if (window.confirm('Biztosan menti az új feladatot?')) {
                let param = {
                    id: myState.guid,
                    task: myState
                }
                await Api.updateTask(param);
                window.location.assign("/edit-task/" + task.guid);
            }
        } else {
            alert('A mezők kitöltése kötelező!');
        }

    }


    
    let customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: myState.isFocused ? '1px solid #96c13d' : '0',
            boxShadow: myState.isFocused ? '0 0 0 1px #96c13d' : 'nonde',
            '&:hover': {
                border: myState.isFocused ? '1px solid #96c13d' : '0',
                boxShadow: myState.isFocused ? '0 0 0 1px #96c13d' : 'nonde',
            }
        }),

    }

    return (
        <>
            <div className='new-task'>
                <div className={"page-head my-2 d-flex"}>
                    <h3 className="title"><strong>Új feladat</strong></h3>
                    <div>
                        <Link to={"/"} className={"btn button"}>{"< Vissza"} </Link>
                    </div>
                </div>
                <div className='input-holder'>
                    <label htmlFor="new-task-name">Név</label>
                    <input
                        styles={customStyles}
                        className={"input new-task-input"}
                        type="text"
                        placeholder="Név"
                        value={myState.name ? myState.name : ''}
                        onChange={(e) => setInputValue('name', e.target.value)}
                    />
                </div>
                <div className='input-holder'>
                    <label htmlFor="new-task-table_id">Táblák</label>
                    <Select
                        styles={customStyles}
                        inputId={'new-task-table_id'}
                        isMulti={true}
                        value={myState.table_id ? myState.table_id : null}
                        options={tableOptions}
                        onChange={(val) => setInputValue( 'table_id', val )}
                    />
                </div>
                <div className='input-holder'>
                    <label htmlFor="new-task-farm_id">Gazdaság</label>
                    <Select
                        styles={customStyles}
                        inputId={'new-task-farm_id'}
                        value={myState.farm_id ? myState.farm_id : 0}
                        options={farmOptions}
                        onChange={(val) => setInputValue( 'farm_id', val )}
                    />
                </div>
                <div className='input-holder'>
                    <label htmlFor="new-task-operation_id">Művelet</label>
                    <Select
                        styles={customStyles}
                        inputId={'new-task-operation_id'}
                        value={myState.operation_id ? myState.operation_id : 0}
                        options={operationOptions}
                        onChange={(val) => setInputValue( 'operation_id', val )}
                    />
                </div>

                <button className={"btn button align-self-end mt-2"} onClick={submitData}>Feladat létrehozása</button>
            </div>
        </>
    );

}
/*class NewTask extends React.Component {

    constructor(props) {
        super(props);
        let guid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
        );
        const { user } = Store.getState();
        this.state = {
            guid: guid,
            name: null,
            harvest_year_id: null,
            table_id: null,
            farm_id: null,
            operation_id: null,
            started_at: null,
            completed_at: null,
            dirty: 1,
            harvest_year_name: null,
            id: null,
            sub_tasks: [{ user_id: user.id }],
            task_area: '',
            task_cost: ''
        }

    }


    setInputValue(property, val) {
        this.setState({
            [property]: val
        });
    }

    async submitData() {
        let task = this.state;
        let { actualHarvestYear } = Store.getState();
        if (task.name && task.farm_id && task.table_id && task.operation_id) {


            task.farm_id = Number(task.farm_id.value);
            task.harvest_year_id = actualHarvestYear;
            task.operation_id = Number(task.operation_id.value);
            let tableIds = [];

            task.table_id.forEach(item => {
                tableIds.push(item.value);
            });
            task.table_id = tableIds;
            if (window.confirm('Biztosan menti az új feladatot?')) {
                let param = {
                    id: this.myState.guid,
                    task: this.state
                }
                await Api.updateTask(param);
                window.location.assign("/edit-task/" + task.guid);
            }
        } else {
            alert('A mezők kitöltése kötelező!');
        }
    }

    render() {
        let { tables, farms, operations } = Store.getState();

        let tableOptions = [];
        tables.forEach(item => {
            tableOptions.push({ value: item.table_id, label: item.name });
        });
        let farmOptions = [];
        farms.forEach(item => {
            farmOptions.push({ value: item.id, label: item.name });
        });
        let operationOptions = [];
        operations.forEach(item => {
            if (this.myState.farm_id && this.myState.farm_id.value) {
                if (Number(item.farm_id) === Number(this.myState.farm_id.value)) {
                    operationOptions.push({ value: item.id, label: item.name });
                }
            } else {
                operationOptions.push({ value: item.id, label: item.name });
            }
        });
        let customStyles = {
            control: (provided, state) => ({
                ...provided,
                border: myState.isFocused ? '1px solid #96c13d' : '0',
                boxShadow: myState.isFocused ? '0 0 0 1px #96c13d' : 'nonde',
                '&:hover': {
                    border: myState.isFocused ? '1px solid #96c13d' : '0',
                    boxShadow: myState.isFocused ? '0 0 0 1px #96c13d' : 'nonde',
                }
            }),

        }
        return (
            <>
                <div className='new-task'>
                    <div className={"page-head my-2 d-flex"}>
                        <h3 className="title"><strong>Új feladat</strong></h3>
                        <div>
                            <Link to={"/"} className={"btn button"}>{"< Vissza"} </Link>
                        </div>
                    </div>
                    <div className='input-holder'>
                        <label htmlFor="new-task-name">Név</label>
                        <input
                            styles={customStyles}
                            className={"input new-task-input"}
                            type="text"
                            placeholder="Név"
                            value={this.myState.name ? this.myState.name : ''}
                            onChange={(e) => this.setInputValue('name', e.target.value)}
                        />
                    </div>
                    <div className='input-holder'>
                        <label htmlFor="new-task-table_id">Táblák</label>
                        <Select
                            styles={customStyles}
                            inputId={'new-task-table_id'}
                            isMulti={true}
                            value={this.myState.table_id ? this.myState.table_id : null}
                            options={tableOptions}
                            onChange={(val) => this.setState({ table_id: val })}
                        />
                    </div>
                    <div className='input-holder'>
                        <label htmlFor="new-task-farm_id">Gazdaság</label>
                        <Select
                            styles={customStyles}
                            inputId={'new-task-farm_id'}
                            value={this.myState.farm_id ? this.myState.farm_id : 0}
                            options={farmOptions}
                            onChange={(val) => this.setState({ farm_id: val })}
                        />
                    </div>
                    <div className='input-holder'>
                        <label htmlFor="new-task-operation_id">Művelet</label>
                        <Select
                            styles={customStyles}
                            inputId={'new-task-operation_id'}
                            value={this.myState.operation_id ? this.myState.operation_id : 0}
                            options={operationOptions}
                            onChange={(val) => this.setState({ operation_id: val })}
                        />
                    </div>

                    <button className={"btn button align-self-end mt-2"} onClick={() => { this.submitData() }}>Feladat létrehozása</button>
                </div>
            </>
        );
    }

}

export default NewTask;
*/