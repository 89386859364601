import React, {useState} from 'react';
import Store from "../store/Store";
import {Link, useParams} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck} from '@fortawesome/free-regular-svg-icons'


export default (props) => {
    let params = useParams();
    let tasksByTable = false;
    let table = false;
    let {tasks, tables} = Store.getState();
    const navigate = useNavigate();

    const [searchKey, setSearchKey] = useState('');

    const convertStringArrayToIntegerArray = (strArr) => {
        for (let i = 0; i < strArr.length; i++) {
            strArr[i] = Number(strArr[i]);
        }
        return strArr;
    }
    if (params.tableId) {
        table = tables.find(element => element.table_id === params.tableId);
        tasksByTable = tasks.filter(element => convertStringArrayToIntegerArray(element.table_id).includes(Number(params.tableId)));
    }

    const tableName = (id) => {
        let table = tables.find((table) => {
            return Number(table.table_id) === Number(id)
        });
        return table ? table.name : '';
    }
    const redirectToTable = (e, url) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(url);
    }

    const renderTask = (task) => {
        return (
            <Col key={'task_' + task.id} xs={12} md={6} lg={4}>
                <Card border="light" className="mb-2" onClick={(e) => {
                    redirectToTable(e, "/edit-task/" + task.guid)
                }}>
                    <Card.Header><strong><FontAwesomeIcon className="icon" icon={'seedling'}/> {task.name}
                    </strong>{task.completed_at ?
                        <FontAwesomeIcon className="icon" style={{float: 'right'}} icon={faCircleCheck}/> : <></>}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col><span><strong>Tábla</strong></span></Col>
                            <Col><span><button className={"btn-link"} onClick={(e) => {
                                redirectToTable(e, "/task/" + task.table_id)
                            }}>{tableName(task.table_id)}</button></span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Aratási év</strong></span></Col>
                            <Col><span>{task.harvest_year_name}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Terület</strong></span></Col>
                            <Col><span>{task.task_area}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Költség/ha</strong></span></Col>
                            <Col><span>{task.task_cost}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Kezdés</strong></span></Col>
                            <Col><span>{task.started_at}</span></Col>
                        </Row>
                        {task.completed_at && <Row>
                            <Col><span><strong>Befejezés</strong></span></Col>
                            <Col><span>{task.completed_at}</span></Col>
                        </Row>}
                    </Card.Body>
                </Card>
            </Col>
        )
    }
    const renderHead = () => {
        return (<div className={"page-head my-2 d-flex"}>
            <h3 className="title"><strong>{table.name}</strong></h3>

            <div>
                <Link to={"/"} className={"btn button"}>{"< Vissza"} </Link>
            </div>
        </div>);
    }

    const newTaskOnTable = () => {

        navigate("/new-task/" + table.table_id);
    }
    if (table) {
        return (
            <div>
                {renderHead()}
                {
                    <>
                        <hr/>
                        <Row>
                            <Col><span><strong>Terület</strong></span></Col>
                            <Col><span>{table.area}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Parcellák száma</strong></span></Col>
                            <Col><span>{table.parcel_count}</span></Col>
                        </Row>
                        <Row>
                            <Col><span><strong>Tervezett fővetés</strong></span></Col>
                            <Col><span>{table.main_crop}</span></Col>
                        </Row>
                    </>
                }
                <hr/>
                <Row>
                    {tasksByTable && tasksByTable.length ? tasksByTable.map((task) => renderTask(task)) : 'Jelenleg nincs faladat ehez a táblához'}
                </Row>
                <div className="float-button" onClick={newTaskOnTable}>
                    <FontAwesomeIcon icon="plus-circle"></FontAwesomeIcon>
                </div>
            </div>
        )
    } else {
        let filteredTasks = tasks.filter(task => task.name.includes(searchKey));
        return (
            <>
                <input className={'search-input'} onChange={(e) => {
                    setSearchKey(e.target.value);
                }} type="text" placeholder={'Keresés...'}/>
                <Row>
                    {filteredTasks && filteredTasks.length && filteredTasks.map((task) => {
                        return renderTask(task)
                    })}
                </Row>
                <div className="float-button">
                    <FontAwesomeIcon icon="plus-circle"></FontAwesomeIcon>
                </div>
            </>
        )
    }

}