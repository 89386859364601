import React from 'react';
import InputField from "./_components/InputField";
import SubmitButton from "./_components/SubmitButton";
import Api from "../api/Api";
import {Link} from "react-router-dom";

class LoginForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            buttonDisabled: false
        };
    }
    setInputValue(property, val){
        val = val.trim();
        this.setState({
            [property]:val
        });
    }

    resetForm(){
        this.setState({
            username: '',
            password: '',
            buttonDisabled: false
        });
    }

    async doLogin(){
        if(!this.state.username){
            return;
        }
        if(!this.state.password){
            return;
        }
        this.setState({buttonDisabled:true});
        try{
            await Api.login(this.state.username,this.state.password);
            this.resetForm();
        }catch(e){
            console.log(e);
            this.resetForm();
        }
    }
    render() {
        return (
            <div className={"login-form"}>
                <h4>Bejelentkezés</h4><br/>
                <InputField
                    type="text"
                    placeholder="Username"
                    value={this.state.username? this.state.username:''}
                    onChange={(val)=>this.setInputValue('username',val)}
                />
                <InputField
                    type="password"
                    placeholder="Password"
                    value={this.state.password? this.state.password:''}
                    onChange={(val)=>this.setInputValue('password',val)}
                />
                <SubmitButton
                    text="Belépés"
                    disabled={this.state.buttonDisabled}
                    onClick={()=>this.doLogin()}
                />
                <br/><br/>
                <Link to={'/forgotten-password'}>Elfelejtett jelszó</Link>
            </div>
        )
    }
}

export default LoginForm;