function getConstants() {
    if(window.location.hostname === 'localhost'){
        return {//ServiceWorkerben is át kell írni az sajnos ezt a file-t nem éri el
            API_URL: 'https://agrekordmobile.lh/rest/v2/',
            POST_HEADER: {headers: {'Content-Type': 'application/json'}},
            IDB_NAME: 'Agrekord',
            IDB_VERSION: 1,
            APP_VERSION: 'v1.0',
        };
    }

    return {//ServiceWorkerben is át kell írni az sajnos ezt a file-t nem éri el
        API_URL: window.location.origin + '/rest/v2/',
        POST_HEADER: {headers: {'Content-Type': 'application/json'}},
        IDB_NAME: 'Agrekord',
        IDB_VERSION: 1,
        APP_VERSION: 'v1.1.0',
    };
}

const constants = getConstants();
export default constants;
